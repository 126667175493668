<template>
  <!--begin::Advance Table Widget 2-->
  <div class="card card-custom card-stretch gutter-b">
    <!--begin::Header-->
    <div class="card-header border-0 pt-5">
      <b-container fluid style="padding-right: 12.5px; padding-left: 12.5px">
        <b-row class="my-1">

          <b-col sm="6">
            <b-row class="my-1">
              <div  class="d-flex justify-content-between mt-n5">
                <label
                    class="font-size-h6 font-weight-bolder text-dark pt-5"
                    >商品查询:</label
                >
                <span
                    class="text-primary font-size-h8 font-weight-bolder text-hover-primary pt-5"
                >（共：{{ vegetable.totalItem }}条，搜索到：{{ vegetable.showItem }}条）</span>
              </div>
            </b-row>
            <b-row class="my-1">
              <b-col sm="6" style="padding: 0;">
                <b-form-input
                  v-model="search.vegetableName"
                  placeholder="请输入商品名称"
                  @change="searchVegetable()"
                ></b-form-input>
              </b-col>
              <b-col sm="6" style="padding: 0;">
                <b-form-input
                  v-model="vegetable.supplierName"
                  placeholder="请输入供应商名称"
                  @change="searchVegetable()"
                ></b-form-input>
              </b-col>
            </b-row>
          </b-col>

          <b-col sm="2">
            <b-row class="my-1">
              <label style="color: white">.</label>
            </b-row>
            <b-row class="my-1">
              <b-button
                variant="primary"
                @click="vegetable.ids = []"
                style="margin-right: 10px"
                >全不选</b-button
              >
            </b-row>
          </b-col>
          
          <b-col sm="4">
            <b-row class="my-1">
              <div  class="d-flex justify-content-between mt-n5">
                <label
                    class="font-size-h6 font-weight-bolder text-dark pt-5"
                    >供应商名称查询:</label
                >
                <span
                    class="text-primary font-size-h8 font-weight-bolder text-hover-primary pt-5"
                >（共：{{ supplier.totalItem }}条，搜索到：{{ supplier.showItem }}条）</span>
              </div>
            </b-row>
            <b-row class="my-1">
              <b-form-input
                v-model="search.supplierName"
                placeholder="请输入供应商名称"
                @change="searchSupplier()"
              ></b-form-input>
            </b-row>
          </b-col>
        </b-row>
      </b-container>
    </div>

    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body pt-3 pb-0">
      <!--begin::Table-->
      <div class="table-responsive">
        <b-row>  
        <b-col sm="7">
            <table class="table table-vertical-center">
            <thead>
                <tr>
                <th class="pl-0 text-center" style="width: 50px">#</th>
                <th class="pl-0" style="min-width: 150px">商品名称</th>
                <th class="pl-0" style="min-width: 100px">供应商</th>
                </tr>
            </thead>
            <tbody>
                <template v-for="(item, i) in vegetableList">
                <tr 
                    v-bind:key="i" 
                    v-show="item.show || vegetable.ids.includes(item.id)" 
                    class="data-tr"
                    @click="vegetableSelectChange(item.id)"
                    :class="`${ vegetable.id == item.id ? 'active-tr' : '' }`"
                >
                    <td class="pl-0 text-center">
                    <b-form-checkbox  
                        :checked="vegetable.ids.includes(item.id)">{{ i + 1 }}</b-form-checkbox>
                    </td>

                    <!-- 商品名称   -->
                    <td class="pl-0">
                    {{ item.name }}
                    </td>

                    <!-- 供应商  -->
                    <td class="pl-0">
                    {{ item.supplierName }}
                    </td>
                </tr>
                </template>
            </tbody>
            </table>
        </b-col>
        <b-col sm="5">
            <table class="table table-vertical-center">
            <thead>
                <tr>
                <th class="pl-0 text-center" style="width: 50px">#</th>
                <th class="pl-0" style="min-width: 100px">供应商</th>
                <th class="pl-0" style="min-width: 50px">操作</th>
                </tr>
            </thead>
            <tbody>
                <template v-for="(item, i) in supplierList">
                <tr 
                    v-bind:key="i" v-show="item.show" class="data-tr"
                    :class="`${ supplier.id == item.id ? 'active-tr' : '' }`"
                >
                    <td class="pl-0 text-center">
                    {{ i + 1 }}
                    </td>

                    <!-- 供应商  -->
                    <td class="pl-0">
                    {{ item.name }}
                    </td>

                    <!-- 操作 -->
                <td class="pl-0">
                  <!-- 编辑 -->
                  <a
                    class="btn btn-icon btn-light btn-sm "
                    title="设置为选中商品的供应商"
                    @click.prevent="supplierSelected(item)"
                  >
                    <span class="svg-icon svg-icon-md svg-icon-primary">
                      <!--begin::Svg Icon-->
                      <inline-svg
                        title="设置为选中商品的供应商"
                        src="media/svg/icons/Communication/Write.svg"
                      ></inline-svg>
                      <!--end::Svg Icon-->
                    </span>
                  </a>
                </td>
                </tr>
                </template>
            </tbody>
            </table>
        </b-col>
        </b-row>
      </div>
      <!--end::Table-->
    </div>
    <!--end::Body-->
    <!--end::Advance Table Widget 2-->

  </div>
</template>

<style lang="scss">
.my-1 {
  padding-right: 10px;
}
.mtop-50 {
  margin-top: -50px !important;
}
.data-tr {
    cursor: pointer;
}
.data-tr:hover {
    background-color: #e1f0ff;
}
.active-tr {
    background-color: #c9f7f5;
}
.active-tr:hover {
    background-color: #c9f7f5;
}
</style>

<script>
let that;

import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters } from "vuex";

import SupplierVegetable from "@/assets/js/pages/vegetable/supplier-vegetable.js";

export default {
  name: "supplier-vegetable",

  data() {
    return {
      search: {
        pageNum: 1,
        // pageSize: 5,
        vegetableName: "",
        supplierName: ""
      },
      rows: 100,
      vegetableList: [],
      supplierList: [],
      supplier: {
        showItem: 0,
        totalItem: 0,
        id: 0
      },
      vegetable: {
        showItem: 0,
        totalItem: 0,
        ids: [],
        supplierName: ""
      }
    };
  },
  computed: {
    ...mapGetters(["layoutConfig"]),
  },
  components: {
  },
  watch: {
  },
  mounted() {
    that = this;
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "账户管理", route: "" },
      { title: "账户列表" }
    ]);
    this.getDataList();
  },
  methods: {
    vegetableSelectChange: function(id) {
        var index = that.vegetable.ids.indexOf(id);
        if (index >= 0) {
            that.vegetable.ids.splice(index, 1)
        } else {
            that.vegetable.ids.push(id)
        }
    },
    getDataList: function () {
      SupplierVegetable.getDataList().then(({ data }) => {
        that.vegetableList = data.vegetableList;
        that.supplierList = data.supplierList;

        that.vegetable.showItem = data.vegetableList.length;
        that.vegetable.totalItem = data.vegetableList.length;

        that.supplier.showItem = data.supplierList.length;
        that.supplier.totalItem = data.supplierList.length;
      });
    },
    searchSupplier: function() {
      that.supplier.showItem = 0;
      that.supplier.totalItem = 0;

      if (that.search.supplierName == "") {
        that.showAllSupplier();
      } else {
        that.showOrHideSupplier();
      }
    },
    showAllSupplier: function() {
      for (var item of that.supplierList) {
          item.show = true;
          that.supplier.showItem += 1;
          that.supplier.totalItem += 1;
        }
    },
    showOrHideSupplier: function() {
      for (var item of that.supplierList) {
        if (item.name.indexOf(that.search.supplierName) != -1) {
          item.show = true;
          that.supplier.showItem += 1;
        } else {
          item.show = false;
        }
        that.supplier.totalItem += 1;
      }
    },
    searchVegetable: function() {
      that.vegetable.showItem = 0;
      that.vegetable.totalItem = 0;

      if (that.search.vegetableName == "" && that.vegetable.supplierName == "") {
        that.showAllVegetable();
      } else {
        that.showOrHideVegetable();
      }
    },
    showAllVegetable: function() {
      for (var item of that.vegetableList) {
          item.show = true;
          that.vegetable.showItem += 1;
          that.vegetable.totalItem += 1;
        }
    },
    showOrHideVegetable: function() {
      for (var item of that.vegetableList) {
        if (
          item.name.indexOf(that.search.vegetableName) != -1
          && item.supplierName.indexOf(that.vegetable.supplierName) != -1
        ) {
          item.show = true;
          that.vegetable.showItem += 1;
        } else {
          item.show = false;
        }
        that.vegetable.totalItem += 1;
      }
    },
    supplierSelected: function(supplier) {
      let supplierId = supplier.id;
      that.supplier.id = supplierId;

      if (that.vegetable.ids.length == 0) {
          return;
      }

      SupplierVegetable.save({
          supplierId: supplierId,
          vegetableIdList: that.vegetable.ids
      }).then(({ msg }) => {
        for (var item of that.vegetableList) {
            if (that.vegetable.ids.includes(item.id)) {
                item.supplierId = supplierId;
                item.supplierName = supplier.name;
            }
        }
        window.Toast("success", msg);
      });
    }
  }
};
</script>
